import React from "react";

const HomePage = () => {
  return (
    <>
      <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div>
            <img
              className="mx-auto w-full h-auto"
              src={require("../assets/imgs/iringa.png")}
              alt="PCM"
            />
            <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start">
              <a
                href="/register"
                className="rounded-md bg-white px-3.5 py-1.5 text-base font-semibold leading-7 text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
              >
                Register Now
              </a>
              <a
                href="/signin"
                className="text-base font-semibold leading-7 text-black"
              >
                Sign In <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
