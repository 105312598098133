import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomWidth: 1,
        padding: 20,
      }}
    >
      <div className="flex items-center" onClick={() => navigate("/")}>
        <img
          className="h-6 w-auto sm:h-6"
          src={require("../assets/imgs/pcm.png")}
          alt=""
        />
        <p className="text-base font-bold cursor-pointer mx-2">TUCASA STU</p>
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p
          onClick={() => navigate("/signin")}
          className="text-sm font-light mx-7 cursor-pointer hover:font-normal"
        >
          Sign in
        </p>
        <p
          onClick={() => navigate("/register")}
          className="text-sm font-light cursor-pointer hover:font-normal"
        >
          Register
        </p>
      </div> */}
    </div>
  );
};

export default Header;
