import React from "react";
import { getEvents, getBranches, createMember } from "../controllers";
import LoadingSpinner from "../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

import { TreeSelect } from "primereact/treeselect";

const Register = () => {
  const navigate = useNavigate();

  const [events, setEvents] = React.useState([]);
  const [allBranches, setAllBranches] = React.useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = React.useState(-4);
  const [zone, setZone] = React.useState(null);

  const [firstN, setFirstN] = React.useState(null);
  const [lastN, setLastN] = React.useState(null);
  const [proffesion, setProfession] = React.useState(null);
  const [event, setEvent] = React.useState(null);
  const [gender, setGender] = React.useState("male");
  const [course, setCourse] = React.useState(null);
  const [phone, setPhone] = React.useState(null);
  const [baptism, setBaptism] = React.useState(true);
  const [err, setErr] = React.useState(null);
  const [pass, setPass] = React.useState(false);
  const [isSubmiting, setIsSubmitting] = React.useState(false);
  const [isOther, setIsOther] = React.useState(false);

  React.useEffect(() => {
    setIsOther(false);
    getBranches()
      .then((res) => {
        if (res.status === 200) {
          setAllBranches(res.data);
        }
      })
      .catch((err) => console.log({ err }));
    getEvents()
      .then((res) => {
        if (res.status === 200) {
          setEvents(res.data);
          setEvent(res.data[0]?._id);
        }
      })
      .catch((error) => console.log({ error }));
  }, []);

  // React.useEffect(() => {
  //   const list = [];
  //   allBranches.forEach((one) => {
  //     if (one.zone?.toUpperCase() === zone?.toUpperCase()) {
  //       list.push(one);
  //     }
  //   });
  //   setBranch(list[0]?._id);
  //   return setBranches(list);
  // }, [zone]);

  const zones = [
    "Central Tanzania Field - CTF",
    "East-Central Tanzania Conference - ECT",
    "Lake Taganyika Field - LTF",
    "South East Tanzania Conference - SEC",
    "Southern Highlands Conference - SHC",
    "Mara Conference - MC",
    "North-East Tanzania Conference - NETC",
    "Nyanza Gold Belt Field - NGBF",
    "South Nyanza Conference - SNC",
    "Tanzania Rift Valley Field - TRVF",
    "Western Tanzania Conference - WTC",
    "Other",
  ];

  const handleSubmit = async () => {
    try {
      if (!firstN || !lastN || !phone || !event) {
        return setErr("Please fill all required fields(*)");
      }
      setIsSubmitting(true);
      const body = {
        first_name: firstN,
        last_name: lastN,
        phone: phone,
        school: course,
        gender: gender,
        profession: proffesion,
        event: events[0]?._id,
        union: zone === null ? zones[0] : zone,
      };

      const response = await createMember(body);
      setIsSubmitting(false);
      if (response.status === 201) {
        setPass(true);
        return navigate("/profile", { state: response.data });
      } else {
        return setErr(response.data.message);
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log({ error });
    }
  };

  const nodes = [
    {
      label: "PASTOR",
      data: "Pastor",
      children: [
        {
          key: "Division Director",
          label: "Division Director",
          data: "Division Director",
        },
        {
          key: "Union Director",
          label: "Union Director",
          data: "Union Director",
        },
        {
          key: "Conference/Field Director",
          label: "Conference/Field Director",
          data: "Conference/Field Director",
        },
        {
          key: "District Pastor",
          label: "District Pastor",
          data: "District Pastor",
        },
        {
          key: "Chaplain",
          label: "Chaplain",
          data: "Chaplain",
        },
      ],
    },
    {
      label: "PROFESSIONAL",
      data: "PROFESSIONAL",
      children: [
        {
          key: "Medical Doctor",
          label: "Medical Doctor",
          data: "Medical Doctor",
        },
        {
          key: "Communication",
          label: "Communication",
          data: "Communication",
        },
        {
          key: "Guest Speaker",
          label: "Guest Speaker",
          data: "Guest Speaker",
        },
        {
          key: "Presenter",
          label: "Presenter",
          data: "Presenter",
        },
        {
          key: "Other",
          label: "Other",
          data: "Other",
        },
      ],
    },
    {
      label: "STUDENT",
      data: "STUDENT",
      children: [
        {
          key: "University Student",
          label: "University Student",
          data: "University Student",
        },
        {
          key: "College Student",
          label: "College Student",
          data: "College Student",
        },
        {
          key: "Institute Student",
          label: "Institute Student",
          data: "Institute Student",
        },
      ],
    },
  ];

  const signUnion = (e) => {
    setZone(e.target.value);
    return setIsOther(false);
  };

  return (
    <div className="sm:my-5 lg:my-16 ">
      <div className="mt-10 sm:mt-0 mx-2 ">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Fill the Registration Form
              </h3>

              {err ? <p className="mt-1 text-sm text-red-600">{err}</p> : null}
            </div>
          </div>
          <div className="mt-5 md:col-span-2 md:mt-0">
            <div className="overflow-hidden shadow sm:rounded-md">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      First name*
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      placeholder="Peter"
                      onChange={(e) => setFirstN(e.target.value)}
                      autoComplete="given-name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Last name*
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setLastN(e.target.value)}
                      name="last-name"
                      id="last-name"
                      placeholder="Mwaba"
                      autoComplete="family-name"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  {/* <div className="col-span-6">
                    <label
                      htmlFor="street-address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Street address*
                    </label>
                    <input
                      type="text"
                      onChange={(e) => setAddress(e.target.value)}
                      name="street-address"
                      id="street-address"
                      autoComplete="street-address"
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div> */}

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="event"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Event Name*
                    </label>
                    {events.length > 0 ? (
                      <input
                        type="text"
                        name="event"
                        id="event"
                        defaultValue={events[0]?.name}
                        disabled
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : null}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="conference"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Conference/Field*
                    </label>
                    <select
                      onChange={(e) =>
                        e.target.value.toLowerCase() === "other" ||
                        e.target.value.toLowerCase() === "others"
                          ? setIsOther(true)
                          : signUnion(e)
                      }
                      id="conference"
                      name="conference"
                      autoComplete="conference-name"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      {zones.map((one, i) => (
                        <option key={i}>{one}</option>
                      ))}
                    </select>
                    {isOther ? (
                      <input
                        type="text"
                        name="union"
                        id="union"
                        placeholder="Type your union"
                        onChange={(e) => setZone(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    ) : null}
                  </div>

                  {/* <div className="col-span-6 ">
                    <label
                      htmlFor="branch"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Branch*
                    </label>
                    <select
                      id="branch"
                      name="branch"
                      onChange={(e) => setBranch(e.target.value)}
                      autoComplete="branch-name"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      {branches.map((one, i) => (
                        <option value={one._id} key={i}>
                          {one.name}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  <div className="col-span-6">
                    <label
                      htmlFor="course"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Institute/College/University
                    </label>
                    <input
                      type="text"
                      name="course"
                      id="course"
                      placeholder="Slobo Tech Institute"
                      onChange={(e) => setCourse(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number*
                    </label>
                    <input
                      type="text"
                      name="phone"
                      id="phone"
                      placeholder="255710100100"
                      onChange={(e) => setPhone(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="branch"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Gender*
                    </label>
                    <select
                      id="branch"
                      name="branch"
                      onChange={(e) => setGender(e.target.value)}
                      autoComplete="branch-name"
                      className="mt-1 block w-full rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    >
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>
                  <div className="col-span-6">
                    <label
                      htmlFor="branchee"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Profession*
                    </label>
                    <TreeSelect
                      className="mt-1 w-full rounded-md border border-gray-300 bg-white  px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm md:w-20rem"
                      value={selectedNodeKey}
                      onChange={(e) => {
                        if (e.value !== "Other") {
                          setProfession(e.value);
                        }
                        setSelectedNodeKey(e.value);
                      }}
                      options={nodes}
                      placeholder="Select your profession"
                      showClear
                    ></TreeSelect>
                  </div>
                  {selectedNodeKey === "Other" ? (
                    <div className="col-span-6">
                      <input
                        type="text"
                        name="proffession"
                        id="profession"
                        placeholder="Write your profession*"
                        onChange={(e) => setProfession(e.target.value)}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
              {pass ? (
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  Welldone, You have successfully registered —{" "}
                  <strong>Welcome to Dar es salaam 🤗</strong>
                </Alert>
              ) : null}
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                {isSubmiting ? (
                  <div className="px-6 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <button
                    onClick={handleSubmit}
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
